import { Step } from "components/common/Stepper/types";
import {
  FAIRFAX,
  IS_BROKER_PORTAL,
  IS_HOSTED_INSURANCE,
  PORTAL_NAME,
} from "config";

export enum StepperStep {
  Start = "Start",
  CustomerDetails = "CustomerDetails",
  Application = "Application",
  Quote = "Quote",
  Payment = "Payment",
  Confirmation = "Confirmation",
}

export const stepperSteps = (filteredSteps?: StepperStep[]): Step[] => {
  let steps: Step[] = [
    {
      label: StepperStep.Start,
    },
    {
      label: StepperStep.CustomerDetails,
    },
    {
      label: StepperStep.Application,
    },
    {
      label: StepperStep.Quote,
    },
    {
      label: StepperStep.Payment,
    },
    {
      label: StepperStep.Confirmation,
    },
  ];

  if(filteredSteps){
    return steps.filter((step) => filteredSteps.includes(step.label as StepperStep));
  }

  if (IS_BROKER_PORTAL) {
    const notNeededSteps = [StepperStep.Payment, StepperStep.Confirmation];

    if (PORTAL_NAME === FAIRFAX) {
      notNeededSteps.push(StepperStep.CustomerDetails);
    }

    steps = steps.filter((step) => !notNeededSteps.includes(step.label as StepperStep));
  }

  if (IS_HOSTED_INSURANCE) {
    const notNeededSteps = [StepperStep.CustomerDetails];

    steps = steps.filter((step) => !notNeededSteps.includes(step.label as StepperStep));
  }

  return steps;
};
