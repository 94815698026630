import { DownloadOutlined, EditOutlined } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getPaymentTokenAPI } from "api/quotes";
import { generatePdfForQuote } from "api/siaApplication";
import applicationUtil from "components/SurveyJS/utils/applicationUtils";
import CustomButton from "components/common/CustomButton/CustomButton";
import PopupModal from "components/common/PopupModal/PopupModal";
import SectionInfo from "components/common/SectionInfo/SectionInfo";
import AnimatedButton from "components/custom/AnimatedButton";
import {
  COUNTRY,
  FAIRFAX,
  IS_BROKER_PORTAL,
  LIBERSEGUROS,
  PORTAL_NAME,
} from "config";
import { animateRouteProps } from "constants/common";
import { otherConfigSelector } from "portalConfig/selectors";
import { setFromLocation } from "store/features/locationStateSlice";
import {
  resetQuotesForSendingEmail,
  sendQuoteForReview,
  sendQuotes,
} from "store/features/quotesSlice";
import {
  authSelector,
  customStylesSelector,
  productsSelector,
  quotesSelector,
  surveyJSDataSelector,
} from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import { CountryToLanguageMapping, getProductTitle } from "utils/utils";
import ErrorMessage from "../ErrorMessage";
import SuccessMessage from "../SuccessMessage";
import { ProductIconMapping } from "../utils";
import ProductQuoteCard from "./ProductQuoteCard";
import QuotesMissing from "./QuotesMissing";
import ShareLinkButton from "./ShareLinkButton";
import Summary from "./Summary";
import { downloadFile } from "utils/file";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </Box>
  );
}

interface QuoteProps {
  submission?: Submission;
  isFromDashboard?: boolean;
  status?: string;
  onQuotesSent?: () => void;
}

const Quote: React.FC<QuoteProps> = ({
  submission,
  isFromDashboard,
  status,
  onQuotesSent,
}) => {
  const { t, lang } = useT();
  const dispatch = useDispatch();
  const customStyles = useSelector(customStylesSelector);
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentLink, setPaymentLink] = useState("");
  const [downloadingQuotePdf, setDownloadingQuotePdf] = useState(false);
  const { quotes, invalidQuotes } = useSelector((state: RootState) => ({
    quotes: state.quotes.availableQuotes,
    invalidQuotes: state.quotes.invalidQuotes,
  }));
  const selectedProducts = useSelector(productsSelector);
  const otherConfig = useSelector(otherConfigSelector);

  const {
    accounts: { selectedAccount },
  } = useSelector(authSelector);
  const {
    sentQuotesToken,
    selectedQuotesForSendingEmail,
    sendingQuotes,
    sentQuotesError,
  } = useSelector(quotesSelector);
  const { updatedSiaApplication, submissionId } = useSelector(
    (state: RootState) => state.surveyJs
  );
  const { portalAccount } = useSelector(authSelector);
  const surveyJSData = useSelector(surveyJSDataSelector);
  const summaryData = isFromDashboard
    ? applicationUtil.generateSummaryData(
        submission?.application_questionnaire,
        submission?.application_params,
        lang
      )
    : applicationUtil.generateSummaryData(
        surveyJSData?.siaApplication?.survey_json,
        surveyJSData?.updatedSiaApplication?.params,
        lang,
        surveyJSData?.updatedSiaApplication?.questions
      );

  const selectedForSendingEmail = (productName: string) =>
    !!selectedQuotesForSendingEmail.find(
      (sq) =>
        sq.product_details?.type?.toLowerCase() === productName.toLowerCase()
    );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleUpdateApplication = () => {
    localStorage.removeItem("prefillData");
    const isFairFax = PORTAL_NAME === FAIRFAX;
    const clientData = {
      prefill_values: isFairFax
        ? { business_email: submission?.application_params?.business_email }
        : { applicant_email: submission?.application_params?.applicant_email },
      read_only_values: isFairFax
        ? { business_email: true }
        : {
            applicant_email: true,
          },
    };
    localStorage.setItem("clientData", JSON.stringify(clientData));
    dispatch(setFromLocation("/home/quotes"));
    navigate(`/newQuote?applicationId=${submission?.application_id}`);
  };

  const downloadQuotePdf = async () => {
    try {
      setDownloadingQuotePdf(true);
      const res = await generatePdfForQuote(quotes[0]?.id, quotes[0]?.account);
      downloadFile(res?.data?.download_url, `${res?.data?.file_name}.pdf`);
    } catch (error) {
      console.error(error);
    } finally {
      setDownloadingQuotePdf(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const fetchPaymentToken = async () => {
    try {
      const response = await getPaymentTokenAPI({
        submissionID: submission?.id,
      });
      if (response?.data?.success && response?.data?.results?.token) {
        const link = `${window.location.origin}/application/payment?token=${response?.data?.results?.token}`;
        setPaymentLink(link);
      }
    } catch (error) {}
  };

  const onSendQuotesClick = () => {
    if (submission) {
      if (PORTAL_NAME === FAIRFAX || PORTAL_NAME === LIBERSEGUROS) {
        dispatch(
          sendQuoteForReview({
            quote_id: selectedQuotesForSendingEmail[0].id,
          })
        );
      } else {
        dispatch(
          sendQuotes({
            applicantEmail: submission?.application_params?.applicant_email,
            applicantName: submission?.application_params?.full_name,
            companyName: portalAccount?.company.company_name,
            selectedQuoteIDs: selectedQuotesForSendingEmail.map((sq) => sq.id),
            submissionID: submission?.id,
            userID: portalAccount?.user?.user_id,
          })
        );
      }
    } else {
      if (PORTAL_NAME === FAIRFAX || PORTAL_NAME === LIBERSEGUROS) {
        dispatch(
          sendQuoteForReview({
            quote_id: selectedQuotesForSendingEmail[0].id,
          })
        );
      } else {
        dispatch(
          sendQuotes({
            applicantEmail: updatedSiaApplication?.params?.applicant_email,
            applicantName: updatedSiaApplication?.params?.full_name,
            companyName: selectedAccount?.name,
            selectedQuoteIDs: selectedQuotesForSendingEmail.map((sq) => sq.id),
            submissionID: submissionId,
            userID: portalAccount?.user?.user_id,
          })
        );
      }
    }
  };

  useEffect(() => {
    dispatch(resetQuotesForSendingEmail());
  }, []);

  useEffect(() => {
    if (submission) {
      fetchPaymentToken();
    }
  }, [submission, sentQuotesToken]);

  useEffect(() => {
    if (sentQuotesToken && status === "quotes_generated" && onQuotesSent) {
      onQuotesSent();
    }
  }, [sentQuotesToken]);

  useEffect(() => {
    if (sentQuotesToken) {
      localStorage.removeItem("currentApplicantID");
      localStorage.removeItem("prefillData");
    }
  }, [sentQuotesToken]);

  const RenderSendQuotesButton = () => (
    <CustomButton
      btnText={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "14px" }}>
            {PORTAL_NAME === FAIRFAX
              ? t("QUOTE_PAGE_SEND_PROPOSAL")
              : t("QUOTE_PAGE_SEND_QUOTES")}
          </Typography>
          {sendingQuotes && (
            <CircularProgress size={14} sx={{ color: "white", ml: "4px" }} />
          )}
        </Box>
      }
      data_cy="send-quotes-button"
      onClickCB={handleOpenModal}
      disabled={!selectedQuotesForSendingEmail?.length || sendingQuotes}
    />
  );

  const RenderUpdateApplicationButton = () => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
      onClick={handleUpdateApplication}
    >
      <AnimatedButton
        variant="outlined"
        sx={{
          position: "relative",
          ml: "20px",
          padding: "8px 0px",
          borderRadius: "12px",
          border: `1px solid ${customStyles?.muiTheme?.palette?.primary?.main}`,
        }}
      >
        <EditOutlined fontSize="small" />
      </AnimatedButton>
    </Box>
  );

  const RenderDownloadQuotePdfButton = () => {
    if (!otherConfig?.allowQuotePdfDownload) {
      return null;
    }

    return (
      <CustomButton
        btnText={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "14px" }}>
              {t("QUOTE_PAGE_DOWNLOAD_QUOTE_PDF")}
            </Typography>
            <DownloadOutlined fontSize="small" sx={{ ml: "4px" }} />
            {downloadingQuotePdf && (
              <CircularProgress size={14} sx={{ ml: "4px" }} />
            )}
          </Box>
        }
        data_cy="download-quote-pdf-button"
        onClickCB={downloadQuotePdf}
      />
    );
  };

  if (!quotes.length) {
    return <QuotesMissing invalidQuote={invalidQuotes?.[0]} />;
  }

  if (PORTAL_NAME === FAIRFAX) {
    // Assumption: There is always one quote for Fairfax
    const lowPremiumError =
      quotes?.[0]?.data?.status?.error?.[CountryToLanguageMapping[COUNTRY]];
    if (lowPremiumError) {
      // Assumption: Technically, quotes are not missing. Just temporarily reusing the same component
      return <QuotesMissing validQuoteError={lowPremiumError} />;
    }
  }

  if (sentQuotesToken && !isFromDashboard) {
    return <SuccessMessage message={t("QUOTE_PAGE_SUCCESS_MESSAGE")} />;
  }

  if (!sentQuotesToken && sentQuotesError && !isFromDashboard) {
    return <ErrorMessage message={sentQuotesError} />;
  }

  // Flags for hiding the section subTitle and description in some portals. For example, Fairfax.
  // More portals can be added in the below array if needed.
  const HIDE_SECTION_SUBTITLE = [FAIRFAX].includes(PORTAL_NAME);
  const HIDE_SECTION_DESCRIPTION = [FAIRFAX].includes(PORTAL_NAME);

  return (
    <>
      <Box
        sx={{
          px: { xs: "20px", sm: "40px", md: "20px", lg: "20px" },
        }}
        {...animateRouteProps}
      >
        {!isFromDashboard && (
          <SectionInfo
            subTitle={
              HIDE_SECTION_SUBTITLE ? "" : t("QUOTE_PAGE_DESCRIPTION_PRIMARY")
            }
            description={
              HIDE_SECTION_DESCRIPTION
                ? ""
                : t("QUOTE_PAGE_DESCRIPTION_SECONDARY")
            }
          />
        )}

        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {selectedProducts.length > 1 ||
              (IS_BROKER_PORTAL && (
                <Tabs value={selectedTab} onChange={handleChange}>
                  {selectedProducts.map((product, index) => (
                    <Tab
                      key={index}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            textTransform: "none",
                            gap: "6px",
                          }}
                        >
                          {IS_BROKER_PORTAL && (
                            <CheckCircleIcon
                              sx={{
                                transition: "0.3s",
                                background: "white",
                                borderRadius: "12px",
                                color:
                                  selectedForSendingEmail(
                                    product?.product?.product_name
                                  ) ||
                                  sentQuotesToken ||
                                  status === "payment_made"
                                    ? "#4CAF50"
                                    : "rgba(0, 0, 0, 0.54)",
                              }}
                            />
                          )}
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: 500 }}
                          >
                            {product.product.product_name}
                          </Typography>
                        </Box>
                      }
                    />
                  ))}
                </Tabs>
              ))}
            {IS_BROKER_PORTAL &&
              (status === "quotes_generated" || !status) &&
              !sentQuotesToken && (
                <Box sx={{ display: "flex" }}>
                  <RenderSendQuotesButton />
                  <RenderDownloadQuotePdfButton />
                  {submission && <RenderUpdateApplicationButton />}
                </Box>
              )}

            {IS_BROKER_PORTAL && paymentLink && status === "quotes_sent" && (
              <ShareLinkButton paymentLink={paymentLink} />
            )}
          </Box>
          {selectedProducts.map((product, index) => (
            <TabPanel
              key={product?.product?.id}
              value={selectedTab}
              index={index}
            >
              <ProductQuoteCard
                prodIcon={ProductIconMapping(
                  product.product.product_identifier
                )}
                prodTitle={getProductTitle(product)}
                plans={quotes.filter(
                  (qt) =>
                    qt.product_details?.type ===
                    product.product.product_identifier
                )}
                key={product.product.id}
              />
            </TabPanel>
          ))}
          <Summary summaryData={summaryData} />
        </Box>
      </Box>
      <PopupModal
        open={isModalOpen}
        title={
          PORTAL_NAME === FAIRFAX
            ? t("POP_UPS.SEND_PROPOSAL_POPUP.title")
            : t("POP_UPS.SEND_QUOTES_POPUP.title")
        }
        content={t("POP_UPS.SEND_QUOTES_POPUP.content")}
        btnText={t("POP_UPS.SEND_QUOTES_POPUP.btnText")}
        onClose={handleCloseModal}
        onSave={onSendQuotesClick}
      />
    </>
  );
};

export default Quote;
