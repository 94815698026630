import { Box, Typography } from "@mui/material";
import { stepperSteps } from "constants/stepperSteps";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { portalFontFamilyNameSelector } from "portalConfig/selectors";
import { customStylesSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import { Step } from "../Stepper/types";

const HorizontalStepper = () => {
  const { steps, currentStep, maxSteps, currentSubStep } = useSelector(
    (state: RootState) => state.stepper
  );
  const { productSelection } = useSelector((state: RootState) => state.client);
  const { surveyModelFuncs } = useSelector(
    (state: RootState) => state.surveyJs
  );
  const fontFamily = useSelector(portalFontFamilyNameSelector);
  const { t } = useT();
  const location = useLocation();
  const title = location?.pathname?.split("/")[2];
  const sectionTitle = title
    ? title.substring(0, 1).toUpperCase() + title.substring(1)
    : "Section Title";
  const [subSteps, setSubSteps] = useState<Step[]>(null);
  const customStyles = useSelector(customStylesSelector);
  const radius = 25;
  const circleWidth = 60;
  const dashArray = radius * Math.PI * 2;
  const percentage = ((currentStep + 1) / maxSteps) * 100;
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  useEffect(() => {
    setSubSteps(
      surveyModelFuncs?.getPageTitles().map((s) => ({ label: s } as Step))
    );
  }, [surveyModelFuncs]);
  return (
    <Box
      sx={{
        display: "flex",
        p: "5px",
        px: "10px",
        gap: "5px",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: customStyles.muiTheme.palette.primary.main,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
        }}
      >
        <svg
          width={circleWidth}
          height={circleWidth}
          viewBox={`0 0 ${circleWidth} ${circleWidth}`}
        >
          <circle
            cx={circleWidth / 2}
            cy={circleWidth / 2}
            strokeWidth={"5px"}
            r={radius}
            style={{
              fill: "none",
              stroke: "#ffffff40",
            }}
          />
          <circle
            cx={circleWidth / 2}
            cy={circleWidth / 2}
            strokeWidth={"5px"}
            r={radius}
            style={{
              fill: "none",
              stroke: "white",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset,
            }}
            transform={`rotate(-90 ${circleWidth / 2} ${circleWidth / 2})`}
          />
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dy="0.3em"
            style={{
              fontSize: "12px",
              fontFamily: fontFamily,
              fontWeight: 500,
              fill: "white",
            }}
          >
            {!productSelection?.length ? currentStep + 1 : currentStep} /{" "}
            {!productSelection?.length ? maxSteps : maxSteps - 1}
          </text>
        </svg>
        <Box>
          <Typography
            sx={{
              color: "white",
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            {t(sectionTitle)}
          </Typography>
          {currentStep === 1 && subSteps?.length && (
            <Typography
              sx={{
                color: "white",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              {subSteps[currentSubStep]?.label}
            </Typography>
          )}
        </Box>
      </Box>
      {currentStep < maxSteps - 1 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "#ffffff40",
              fontSize: "12px",
              fontWeight: 600,
              textAlign: "right",
            }}
          >
            {t("NEXT")}
          </Typography>
          <Typography
            sx={{
              color: "#ffffff60",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            {t(steps[currentStep + 1]?.label)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default HorizontalStepper;
